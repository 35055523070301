.survey .error {
    background-color: red;
    width: 100%;
    padding: 0.5em;
    color: #fff;
}

.survey {
    /* account for the footer */
    margin-bottom: 5em;
}

.main-wrapper {
    min-height: 100%;
}

.survey-logo {
    width: 100%;
    display: flex;
    padding: 1em;
}

.survey-logo.img-left {
    justify-content: flex-start;
}

.survey-logo.img-center {
    justify-content: space-around;
}

.survey-logo.img-right {
    justify-content: flex-end;
}

.survey-logo img {
    max-width: 80%;
}