.header {
    background-color: #4CA9FD;
    color: #ffffff;
    padding: 20px 0px;
    margin-bottom: 30px;
}

.header a {
    color: #ffffff;
}

.header .main-header-area .main-menu ul li {
    display: inline-block;
    position: relative;
    margin: 0 10px;
}

.header ul {
    padding: 0;
    margin: 0;
}

.side-menu {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1F1F1F;
    color: #C7C7C7;
    z-index: 5;
}

.side-menu ul {
    list-style-type: none;
}

.side-menu li {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 1em 0;
    padding: 1em 2em;
    border-bottom: 1px dotted #C7C7C7;
}

.side-menu .left-menu-header {
    border-bottom: 0;
    padding: 0 1em;
    font-size: 1.5em;
}