button.base-btn {
    width: 90%;
    display: inline-block;
    padding: 0.35em 1.2em;
    margin: 2em 0.3em 2em 0;
    border: 0.1em solid;
    border-radius: 0.12em;
    box-sizing: border-box;
    text-decoration:none;
    text-align:center;
    transition: all 0.2s;
}

button.submit-btn:hover {
    background-color: #4CA9FD;
    border-color: #FFFFFF;
    color: #FFFFFF;
}

button.submit-btn {
    background-color: inherit;
    border-color: #4CA9FD;
    color: #4CA9FD;
}

button.secondary-btn:hover {
    background-color: #4CA9FD;
    border-color: #FFFFFF;
    color: #FFFFFF;
}

button.secondary-btn {
    background-color: #E9F4FE;
    border-color: #4CA9FD;
    color: #4CA9FD;
}

@media all and (max-width:30em) {
    button.base-btn {
        display:block;
        margin:0.4em auto;
    }
}