html, body {
  height: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

h1 {
  margin-bottom: 1em;
}

#root {
  height: 100%;
}

.error-message {
  color: red;
}

.blue {
  color: #4CA9FD;
}

.vertical-margin {
  margin-bottom: 30px;
}