.address-lookup {
    position: absolute;
    top: 30%;
    left: 10%;
    width: 80%;
    background-color: #ffffff;
    z-index: 100;
    border: 1px solid #4CA9FD;
}

.address-lookup .address-list {
    max-height: 500px;
    overflow-y: auto;
}

.address-lookup .address-group {
    border-bottom: 1px dotted;
    padding: 1em 0;
}

.address-lookup .popup-header {
    background-color: #4CA9FD;
    color: #ffffff;
    margin: 0;
    padding: 1em;
}

.address-lookup .content {
    padding: 1em;
}

.address-lookup .popup-header h1 {
    margin-bottom: 0;
}