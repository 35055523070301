.help .active {
    background-color: #4CA9FD;
}

.help .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 1em 2em;
}

@media all and (min-width: 800px){
    .help .navbar {
        position: fixed;
        z-index: 2;
    }
}

.help a.nav-link {
    color: #ffffff;
}

.help .topic {
    margin: 20px 0;
    padding: 1em;
    border-bottom: 1px dotted #AAAAAA;
}

.help .screenshot img {
    max-width: 100%;
    max-height: 500px;
}

.help .screenshot .screenshot-title {
    font-weight: 600;
    margin: 1em 0;
    border-bottom: 1px solid;
    color: #4CA9FD;
}