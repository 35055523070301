.login-form {
    background-color: #FFFFFF;
    border: 1px solid #dddddd;
    margin: 5em 2em;
    padding: 1em;
}

@media all and (max-width: 800px){
    .login-form {
        margin: 1em 0 12em 0;
    }
}

.login-form input {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #dddddd;
    font-size: 1.2em;
    height: 3em;
    width: 100%;
}

.hero .login-form h3 {
    color: #333333;
    font-size: 2em;
    line-height: 40px;
}

.login-form .row {
    margin: 1em 0;
}

.login-form button {
    background-color: #4CA9FD;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    height: 3em;
    width: 50%;
}
.login-form a {
    cursor: pointer;
}

.login-form .oauth-button {
    font-size: 2em;
    padding: 5px 15px;
    cursor: pointer;
}
