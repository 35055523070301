.question-block {
    margin: 1em 0;
    padding: 1em;
    background-color: #E9F4FE;
    border: 1px dotted #0181F5;
    position: relative;
}

.delete-btn {
    cursor: pointer;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333333;
    opacity: 0.6;
    z-index: 99;
    visibility: hidden;
}

.overlay.visible {
    visibility: visible;
}

.page-break {
    background-color: #b9ceeb;
    border-color: #a6b1e1;
}

.answer-piping {
    position: absolute;
    right: 20px;
    top: 50px;
    z-index: 2;
    background-color: #333333;
    color: #fff;
    padding: 10px;
    list-style-type: none;
    width: 50%;
}

.answer-piping .form-field {
    color: #fff;
}

.answer-piping-icon {
    cursor: pointer;
}