.axis {
    font: 10px sans-serif;
  }

.axis path,
.axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
}

/* .axis.x .tick text {
    transform: rotate(-90deg);
    text-align: left;
} */
